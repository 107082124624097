import React from "react";
import PropTypes from "prop-types";

import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Header from "root/sections/Header";

import GetStartedForm from "root/sections/GetStartedForm";
import Footer from "root/sections/Footer";

const METATAGS = {
  title: "Drivit | Get Started",
  description:
    "Get deeper into Drivit, a technology that is changing the way people travel. Together we’ll make driving safer, cheaper and more ecological.",
  keywords:
    "Drivit get started, Contact Drivit, Contact driving analytics tool, Contact driving analytics technology, Contact telematics technology",
};

const GetStartedPage = ({ location }) => {
  return (
    <div>
      <SEO
        title={METATAGS.title}
        description={METATAGS.description}
        keywords={METATAGS.keywords}
      />
      <Layout>
        <Header currentPage="get-started" background="greenWhite" />
        <GetStartedForm
          stateEmail={location.state ? location.state.email : ""}
        />
        <Footer />
      </Layout>
    </div>
  );
};

GetStartedPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      email: PropTypes.string,
    }),
  }).isRequired,
};

export default GetStartedPage;
