import React from "react";
import PropTypes from "prop-types";

import Typography from "root/components/Typography";
import Section from "root/components/Section";
import GetStartedSalesForce from "root/components/GetStartedSalesForce";

import styles from "./index.module.css";

const GetStartedForm = ({ stateEmail }) => {
  return (
    <Section color="greenWhite">
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.text}>
              <div className={styles.title}>
                <Typography variant="h2" color="white" weight="bold">
                  Get to know Drivit
                </Typography>
              </div>
              <Typography variant="h2" color="white">
                See how we can help you take advantage of driving data
              </Typography>
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.form}>
              <GetStartedSalesForce stateEmail={stateEmail} />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

GetStartedForm.propTypes = {
  stateEmail: PropTypes.string,
};

GetStartedForm.defaultProps = {
  stateEmail: "",
};

export default GetStartedForm;
