import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import Button from "root/components/Button";
import Typography from "root/components/Typography";

import { motion, AnimatePresence } from "framer-motion";

import styles from "./index.module.css";

const GetStartedSalesForce = ({ stateEmail }) => {
  const [email, setEmail] = useState(stateEmail);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessSector, setBusinessSector] = useState("");
  const [industry, setIndustry] = useState("Other");
  const [dropdown, setDropdown] = useState(false);

  const firstNameRef = useRef();
  const emailRef = useRef();
  const businessSectorRef = useRef();

  const handleEmailInputChange = event => {
    event.preventDefault();

    setEmail(event.target.value);
  };

  const handleFirstNameInputChange = event => {
    event.preventDefault();

    setFirstName(event.target.value);
  };

  const handleLastNameInputChange = event => {
    event.preventDefault();

    setLastName(event.target.value);
  };

  const handleBusinesSectorInputChange = event => {
    event.preventDefault();

    setBusinessSector(event.target.value);

    if (event.target.value === "Insurance") {
      setIndustry("Digital Insurance");
    } else {
      setIndustry("Other");
    }
  };

  const handleBusinesSectorFocus = event => {
    event.preventDefault();

    setDropdown(true);
  };

  const handleBusinesSectorBlur = event => {
    event.preventDefault();

    setDropdown(false);
  };

  const handleBusinesSectorPressEsc = event => {
    if (event.keyCode === 27) {
      setDropdown(false);
    }
  };

  const handleDropdownSelect = option => event => {
    event.preventDefault();

    businessSectorRef.current.setCustomValidity("");
    setBusinessSector(option);

    if (option === "Insurance") {
      setIndustry("Digital Insurance");
    } else {
      setIndustry("Other");
    }
  };

  useEffect(() => {
    firstNameRef.current.setCustomValidity(" ");
    businessSectorRef.current.setCustomValidity(" ");

    if (email === "") {
      emailRef.current.setCustomValidity(" ");
    } else {
      emailRef.current.setCustomValidity("");
    }

    firstNameRef.current.addEventListener("input", event => {
      event.preventDefault();

      if (firstNameRef.current.value === "") {
        firstNameRef.current.setCustomValidity(" ");
      } else {
        firstNameRef.current.setCustomValidity("");
      }
    });

    emailRef.current.addEventListener("input", event => {
      event.preventDefault();

      if (emailRef.current.value === "") {
        emailRef.current.setCustomValidity(" ");
      } else {
        emailRef.current.setCustomValidity("");
      }
    });

    businessSectorRef.current.addEventListener("input", event => {
      event.preventDefault();

      if (businessSectorRef.current.value === "") {
        businessSectorRef.current.setCustomValidity(" ");
      } else {
        businessSectorRef.current.setCustomValidity("");
      }
    });
  }, []);

  let successPath = `https://drivit.com/get-started/success?email=${email}&name=${firstName}%20${lastName}`;

  if (typeof window !== `undefined`) {
    successPath = `${window.location.href}/success?email=${email}&name=${firstName}%20${lastName}`;
  }

  return (
    <>
      <form
        action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
        method="POST"
      >
        {/*
        <input type="hidden" name="debug" value="1" />
        <input type="hidden" name="debugEmail" value="luis@auroradigital.co" />
        */}
        <input type="hidden" name="oid" value="00D2X000000RKv7" />
        <input type="hidden" name="retURL" value={successPath} />
        <input type="hidden" id="industry" name="industry" value={industry} />
        <input type="hidden" id="lead_source" name="lead_source" value="Web" />

        <div className={styles.inputText}>
          <input
            className={classNames(styles.input, styles.form)}
            id="first_name"
            maxLength="40"
            name="first_name"
            type="text"
            onChange={handleFirstNameInputChange}
            value={firstName}
            ref={firstNameRef}
            required
            autoComplete="off"
          />
          <Typography color="grey">
            First Name
            <sup>*</sup>
          </Typography>
        </div>
        <div className={styles.inputText}>
          <input
            className={classNames(styles.input, styles.form)}
            id="last_name"
            maxLength="80"
            name="last_name"
            type="text"
            onChange={handleLastNameInputChange}
            value={lastName}
            autoComplete="off"
          />
          <Typography color="grey">Last Name</Typography>
        </div>
        <div className={styles.inputText}>
          <input
            className={classNames(styles.input, styles.form)}
            id="email"
            maxLength="80"
            name="email"
            type="text"
            onChange={handleEmailInputChange}
            value={email}
            ref={emailRef}
            required
          />
          <Typography color="grey">
            Work Email
            <sup>*</sup>
          </Typography>
        </div>
        <div className={styles.inputText}>
          <input
            className={classNames(styles.input, styles.form)}
            id="00N2X000009MOnO"
            maxLength="80"
            name="00N2X000009MOnO"
            type="text"
            onChange={handleBusinesSectorInputChange}
            onFocus={handleBusinesSectorFocus}
            onBlur={handleBusinesSectorBlur}
            onKeyDown={handleBusinesSectorPressEsc}
            value={businessSector}
            ref={businessSectorRef}
            required
            autoComplete="off"
          />
          <Typography color="grey">
            Business Sector
            <sup>*</sup>
          </Typography>
        </div>
        <AnimatePresence initial={false}>
          {dropdown && (
            <motion.div
              className={styles.dropdownWrapper}
              key="dropdown"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.3,
                  ease: "easeIn",
                },
              }}
              exit={{
                opacity: 0,
                transition: {
                  duration: 0.3,
                  ease: "easeOut",
                },
              }}
            >
              <div className={styles.dropdownContent}>
                <div className={styles.dropdown}>
                  <div className={styles.blackBorder}>
                    <button
                      type="button"
                      onClick={handleDropdownSelect("Insurance")}
                      className={styles.selectButton}
                    >
                      <Typography variant="body" color="black">
                        Insurance
                      </Typography>
                    </button>
                  </div>
                  <button
                    type="button"
                    onClick={handleDropdownSelect("Mobility Services")}
                    className={styles.selectButton}
                  >
                    <Typography variant="body" color="black">
                      Mobility Services
                    </Typography>
                  </button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <Button color="lightGreen" size="full" type="submit" name="submit">
          <Typography color="white" weight="bold">
            Get Started
          </Typography>
        </Button>
      </form>
      <div className={styles.scheduleButton}>
        <a
          href={`https://calendly.com/goncalo-farinha?email=${email}&name=${firstName}%20${lastName}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Button color="whiteBorderGreen" size="full">
            <Typography color="lightGreen" weight="bold">
              Schedule a call with us
            </Typography>
          </Button>
        </a>
      </div>
    </>
  );
};

GetStartedSalesForce.propTypes = {
  stateEmail: PropTypes.string,
};

GetStartedSalesForce.defaultProps = {
  stateEmail: "",
};

export default GetStartedSalesForce;
